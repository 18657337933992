require('./tippy');

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import mask from '@alpinejs/mask';
import Headroom from "headroom.js";

import '@algolia/autocomplete-theme-classic';
import { autocomplete } from '@algolia/autocomplete-js';
import { search, createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';

import instantsearch from "instantsearch.js";
import { searchBox,
          hits,
          highlight,
          pagination,
          hierarchicalMenu,
          refinementList,
          infiniteHits,
       } from "instantsearch.js/es/widgets";


var headroomOptions = {
      offset: {
        up: 150,
        down: 300,
      },
      tolerance : {
        up : 50,
        down : 0
      },
    };
// TODO: Turn off on some pages (PDP?)
var header = document.querySelector("#mainNavbar");
if (typeof(header) != 'undefined' && header != null){
  var headroom = new Headroom( header , headroomOptions );
  headroom.init();
  window.Headroom = headroom;
}


window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(mask);
Alpine.start();
